<template>
    <div>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->


                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end"
                            v-if="this.$hasPermission('create-corporate-account')">
                            <b-form-input @keyup="checkString($event)" v-model="searchQuery" class="d-inline-block mr-1"
                                placeholder="Search..." :class="{ red_border: notEmpty }" />
                        </div>
                    </b-col>
                    <b-col cols="12" md="1">
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-primary" @click="masterSearch()">
                                Filter
                            </button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table ref="refUserListTable" class="position-relative" responsive primary-key="id" :items="listClients"
                :fields="fields" empty-text="No matching records found" show-empty :per-page="0" id="my-table"
                :current-page="currentPage">
                <!-- Column: Actions -->
                <template #cell(actions)="{ item }">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL"
                        transition="scale-transition" :offset-y="true">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <template style="padding: 0" v-slot:activator="{ on, attrs }">
                            <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                            </b-btn>
                        </template>
                        <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                            <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false"
                                v-if="$hasPermission('delete-records')">
                                <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px"
                                    @click="RestoreCompany(item.ca_id, false)">
                                    <feather-icon icon="TrashIcon" />
                                    Restore
                                </b-list-group-item>
                            </b-list-group-item>
                        </b-list-group>

                    </b-dropdown>
                </template>
            </b-table>
            <div class="mx-2 mb-2 pt-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of
                            {{ totalItems }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <div class="pagination_block">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                                        <button type="button" class="page-link"
                                            @click="getClientes(pagination.first_page_url)">
                                            &laquo;
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                                        <button type="button" class="page-link"
                                            @click="getClientes(pagination.prev_page_url)">
                                            Previous
                                        </button>
                                    </li>
                                    <li class="page-item" v-if="currentPage - 2 > 0">
                                        <button type="button" class="page-link" @click="
                                getClientes(
                                    pagination.path + '?page=' + (currentPage - 2)
                                )
                                ">
                                            {{ currentPage - 2 }}
                                        </button>
                                    </li>
                                    <li class="page-item" v-if="pagination.prev_page_url">
                                        <button type="button" class="page-link"
                                            @click="getClientes(pagination.prev_page_url)">
                                            {{ currentPage - 1 }}
                                        </button>
                                    </li>
                                    <li class="page-item">
                                        <button type="button" class="page-link" :class="{
                                current: pagination.current_page == currentPage,
                            }">
                                            {{ currentPage }}
                                        </button>
                                    </li>
                                    <li class="page-item" v-if="pagination.next_page_url">
                                        <button type="button" class="page-link"
                                            @click="getClientes(pagination.next_page_url)">
                                            {{ currentPage + 1 }}
                                        </button>
                                    </li>
                                    <li class="page-item" v-if="currentPage + 2 <= lastPage">
                                        <button type="button" class="page-link" @click="
                                getClientes(
                                    pagination.path + '?page=' + (currentPage + 2)
                                )
                                ">
                                            {{ currentPage + 2 }}
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                                        <button type="button" class="page-link"
                                            @click="getClientes(pagination.next_page_url)">
                                            Next
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                                        <button type="button" class="page-link"
                                            @click="getClientes(pagination.last_page_url)">
                                            &raquo;
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-modal id="modal-change-info-company" centered hide-footer>
            <div class="d-block text-center">

                <b-col>
                    <b-form-group v-if="isValidCompanyName == false">
                        <span style="color: red;">The company name is currently used and a different one must be
                            entered</span>
                        <b-form-input v-model="currentCompanyName" />
                    </b-form-group>
                    <b-form-group v-if="isValidEmailUser == false">
                        <span style="color: red;">Email user is currently used and a different one must be
                            entered</span>
                        <b-form-input v-model="currentEmailUser" />
                    </b-form-group>
                </b-col>
            </div>
            <b-row class="mt-3">
                <b-col>
                    <b-button block @click="RestoreCompany(currentCompanyId, true)">Save</b-button>
                </b-col>
                <b-col>
                    <b-button block @click="$bvModal.hide('modal-center')">Close</b-button>
                </b-col>
            </b-row>
        </b-modal>

    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BFormGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";

import UserListAddNew from "@core/components/infoClients/UserListAddNew";

export default {
    components: {
        UserListAddNew,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BListGroup,
        BListGroupItem,
        BFormSelect,
        vSelect,
        BFormGroup
    },

    data() {
        return {
            perPage: 5,
            pageOptions: [3, 5, 10],
            currentPage: 1,
            listClients: [],
            search: "",
            fields: [
                {
                    key: "ca_id",
                    label: 'Id',
                    sortable: true,
                },
                {
                    key: "company_legal_name",
                    sortable: true,
                },
                {
                    key: "dba",
                    sortable: true,
                },
                {
                    key: "office_location_address",
                    sortable: true,
                },
                {
                    key: "tin",
                    sortable: true,
                },
                {
                    key: "payment_method",
                },
                {
                    key: "actions",
                },
            ],
            totalItems: 0,
            pagination: {},
            lastPage: 0,
            searchQuery: "",
            notEmpty: false,
            isValidEmailUser: true,
            isValidCompanyName: true,
            currentCompanyId: "null",
            currentCompanyName: "",
            currentEmailUser: ""
        };
    },
    methods: {
        checkString(e) {
            if (this.searchQuery == "") {
                this.getClientes();
            }
            if (e.keyCode == 13) {
                this.masterSearch();
            }
            this.notEmpty = false;
        },
        masterSearch(url = "") {
            if (this.searchQuery == "") {
                this.notEmpty = true;
            } else {
                this.notEmpty = false;
                this.$swal({
                    title: "Please, wait...",
                    didOpen: () => {
                        this.$swal.showLoading();
                    },
                });
                if (url == "") {
                    url = `admin/panel/ca/list/search/` + this.searchQuery;
                }

                this.$http
                    .get(url, {
                        params: {
                            isDeleted: 1
                        }
                    })
                    .then((response) => {
                        this.listClients = response.data.data.data;
                        this.totalItems = response.data.data.total;
                        this.currentPage = response.data.data.current_page;
                        this.lastPage = response.data.data.last_page;
                        this.pagination = response.data.data;
                        this.perPage = response.data.data.per_page;
                        this.setIds();
                        this.$swal.close();
                    })
                    .catch((res) => console.log(res.data));
            }
        },
        setIds() {
            this.listClients.forEach(element => {
                element.ca_id = element.id;
            });
        },
        getClientes(url = "") {
            let pagination = 'true';
            let type = 'il';
            if (url == "") {
                url = `/admin/panel/ca/get_deleted_corporate_account_list`;
            }
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });
            this.$http
                .get(url)
                .then((response) => {
                    this.listClients = response.data.data.data;
                    this.totalItems = response.data.data.total;
                    this.currentPage = response.data.data.current_page;
                    this.lastPage = response.data.data.last_page;
                    this.pagination = response.data.data;
                    this.perPage = response.data.data.per_page;
                    this.$swal.close();
                })
                .catch((res) => {
                    console.log(res.data)
                    this.$swal.close();
                }
                );
        },
        async RestoreCompany(id, withDataCompany) {
            this.currentCompanyId = id;
            if (withDataCompany != true) {
                this.currentCompanyName = "";
                this.currentEmailUser = "";
            } else {
                if (this.validateEmail(this.currentEmailUser) == false) {
                    this.$swal({
                        title: "Email invalid",
                        icon: "error",
                    });
                    return false;
                }
            }


            let formData = new FormData();
            formData.append("idCompany", id);
            formData.append("companyName", this.currentCompanyName);
            formData.append("emailUser", this.currentEmailUser);
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });
            let validation = await this.$http
                .post(`/admin/panel/ca/validate_company_restore`, formData)
                .then((res) => {
                    let isValid = true;
                    this.isValidCompanyName = true;
                    this.isValidEmailUser = true;

                    if (res.data.data.companyName == "invalid") {
                        this.isValidCompanyName = false;
                        isValid = false;
                    }
                    if (res.data.data.emailUser == "invalid") {
                        this.isValidEmailUser = false;
                        isValid = false;
                    }
                    return isValid;
                })
                .catch((error) => {
                    this.$swal({
                        title: error.response.data.data,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });

            if (validation == false) {
                this.$swal.close();
                this.$bvModal.show("modal-change-info-company");
                return false;
            }

            this.$http
                .post(`/admin/panel/ca/restore_company`, formData)
                .then((res) => {
                    this.getClientes();

                    this.$bvModal.hide("modal-change-info-company");
                    this.currentCompanyName = "";
                    this.currentEmailUser = "";
                })
                .catch((error) => {
                    this.$swal({
                        title: error.response.data.data,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });

        },
        validateEmail(email) {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (email.match(validRegex)) {
                return true
            } else {
                return false;
            }
        }
    },
    computed: {
        rows() {
            return this.listClients.length;
        },
    },
    mounted() {
        console.log("fdas")
        this.getClientes();
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.urlPagina {
    text-decoration: none;
    color: #7367f0;
}

.urlPagina:hover {
    background: linear-gradient(118deg,
            #7367f0,
            rgba(115, 103, 240, 0.7)) !important;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.list-group-item:hover {
    background: linear-gradient(118deg,
            #7367f0,
            rgba(115, 103, 240, 0.7)) !important;
    color: #fff !important;
    cursor: pointer;
}

.urlPagina::before {
    background-color: currentColor !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
    box-shadow: 0px 14px 20px 0px rgba(143, 143, 143, 0.2) !important;
}

.current {
    background: #7367f0;
    color: white;
}

.red_border {
    border: 1px solid red;
    border-radius: 6px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>